.reports-section {
    width: 85%;
    margin: auto;
    padding: 40px;
  }
  
  .reports-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .report-item {
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    justify-content: space-between;
    border-radius: 3px;
    border: #777 solid 0.4px;
    padding: 4px 25px;
    font-size: 1.2rem;

    &:hover {
        cursor: pointer;
        background-color: #f6f6ff;
        border-radius: 5px;
        border: #000000 solid 0.4px;
        box-shadow: #333 1px 1px;
        // font-size: 1.4rem;
    }
  }
  
  .report-index {
    font-weight: bold;
    color: #007bff;
    margin-right: 10px;
  }
  
  .report-title {
    flex-grow: 1;
    text-decoration: underline;
    color: #333;
    font-weight: 500;
    transition: color 0.2s ease-in-out;
  
    &:hover {
      color: #007bff;
      text-decoration: none;
    }
  }
  
  .report-date {
    font-size: 1.2rem;
    color: #777;
    white-space: nowrap;
  }
  
  .no-reports {
    text-align: center;
    font-size: 1rem;
    color: #777;
  }
  
  @media (max-width: 768px) {
    .report-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .report-date {
      margin-top: 5px;
      align-self: flex-end;
    }
  }
  

.admin-reports {
padding: 20px;
max-width: 1100px;
margin: 0 auto;
text-align: center;

.header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.add-report-btn {
    background: #007bff;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 5px;

    &:hover {
    background: #0056b3;
    }
}

.reports-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;

    th, td {
    padding: 12px;
    border: 1px solid #ddd;
    }

    th {
    background-color: #007bff;
    color: white;
    }

    tr:nth-child(even) {
    background-color: #f9f9f9;
    }

    .icon {
    cursor: pointer;
    font-size: 1.2rem;
    margin: 0 8px;
    transition: color 0.3s;
    }

    .edit-icon {
    color: #28a745;

    &:hover {
        color: #218838;
    }
    }

    .delete-icon {
    color: #dc3545;

    &:hover {
        color: #c82333;
    }
    }

    .view-icon {
    color: #007bff;
    margin-right: 8px;
    
    &:hover {
        color: #0056b3;
    }
    }
    
}

.centertext {
    text-align: center;
    padding: 20px;
}
}
  
  